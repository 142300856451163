import Plaatjie from "@ubo/plaatjie"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import ParseContent from "components/shared/ParseContent"


const Box = styled.div`
  background-color: ${props => props.theme.color.contrast};
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 3rem 1rem;

  & span {
    color: ${props => props.theme.color.text.light};
    font-weight: 300;
  }

  @media (max-width: 991px){
    padding: 1rem 1rem 2rem;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    color: ${props => props.theme.color.text.light};
    font-weight: 800;

    
    @media (max-width: 1198px) {
      font-size: 30px !important;
    }

    @media (min-width: 1199px){
      font-size: 50px;
      line-height: 69px;
    }
    
  }

  & p {
    color: ${props => props.theme.color.text.light};
    font-size: 16px;
    line-height: 30px;
  }
`

const Column = styled.div`
  @media (max-width: 991px){
    max-height: 300px;
  }
`

const StyledButton = styled.a`
  background-color: ${props => props.theme.color.secondary};
  border: 2px solid ${props => props.theme.color.secondary};
  display: inline-flex;
  border-radius: 30px;
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.light};  

  & > svg {
    margin-left: 15px;

    @media (min-width: 992px) {
      height: 25px;
      width: 25px;
    }

    @media (max-width: 991px) {
      height: 20px;
      width: 20px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.color.light};
    border-color: ${props => props.theme.color.light};
    color: ${props => props.theme.color.text.secondary};  
  }

  @media (min-width: 992px) {
    font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.xm};
    padding: 12px 65px;
  }

  @media (max-width: 991px) {
    font-size: ${props => props.small ? props.theme.font.size.m : props.theme.font.size.m};
    padding: 10px 25px;
  }
`

const Image = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }
  @media (min-width: 992px){
    margin-top: -12rem;
    margin-left: -4rem;
    height: 450px;
  }

  @media (max-width: 991px){

    display: block;
    margin: 0 auto 2rem;
    overflow: visible;

    & img {
      width: 300px;
      height: auto;
      margin: -2rem auto 2rem;
    }
  }
`

const Infographic = () => {

  const { fields } = useStaticQuery(graphql`
    {
      fields:  wordpressWpComponenten(wordpress_id: {eq: 4311}) {
        acf {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
          title
          description
          button {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <section>
      <div className="container my-5">
        <div className="col-lg-12">
          <Box>
            <Column className="col-lg-4">
              <Image image={fields.acf.image} alt="Infographic" />
            </Column>
            <div className="col-lg-8">
              <Content content={fields.acf.title} />
              <div className="d-flex align-items-center my-3">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23 3.83332H0V19.1668H23V3.83332Z" fill="#F0F0F0" />
                  <path d="M12.9375 3.83318H10.0625V10.0623H0V12.9373H10.0625V19.1665H12.9375V12.9373H23V10.0623H12.9375V3.83318Z" fill="#D80027" />
                  <path d="M17.6896 14.1665L23 17.1168V14.1665H17.6896Z" fill="#0052B4" />
                  <path d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z" fill="#0052B4" />
                  <path d="M20.6027 19.1665L14 15.498V19.1665H20.6027Z" fill="#0052B4" />
                  <path d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z" fill="#F0F0F0" />
                  <path d="M14 14.1665L23 19.1665V17.7526L16.545 14.1665H14Z" fill="#D80027" />
                  <path d="M4.05829 14.1664L0 16.421V14.1664H4.05829Z" fill="#0052B4" />
                  <path d="M9.00002 14.8022V19.1664H1.14511L9.00002 14.8022Z" fill="#0052B4" />
                  <path d="M6.45496 14.1665L0 17.7526V19.1665L9.00001 14.1665H6.45496Z" fill="#D80027" />
                  <path d="M5.31044 8.83317L0 5.88288V8.83317H5.31044Z" fill="#0052B4" />
                  <path d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z" fill="#0052B4" />
                  <path d="M2.39731 3.83318L9.00002 7.50168V3.83318H2.39731Z" fill="#0052B4" />
                  <path d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z" fill="#F0F0F0" />
                  <path d="M9.00001 8.83317L0 3.83318V5.24705L6.45496 8.83317H9.00001Z" fill="#D80027" />
                  <path d="M18.9417 8.83326L23 6.57863V8.83326H18.9417Z" fill="#0052B4" />
                  <path d="M14 8.19743V3.83323H21.8549L14 8.19743Z" fill="#0052B4" />
                  <path d="M16.545 8.83317L23 5.24705V3.83318L14 8.83317H16.545Z" fill="#D80027" />
                </svg>
                <span className="pl-2">Engelstalig</span>
              </div>
              <Content content={fields.acf.description} className="pr-5 mb-4" />
              <StyledButton href={fields.acf.button.localFile.publicURL} target="_blank" rel="noopener noreferrer" className="justify-content-center text-center">Download nu</StyledButton>
            </div>
          </Box>
        </div>
      </div>
    </section>
  )
}

export default Infographic