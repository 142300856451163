/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ImageBackground from 'components/elements/ImageBackground'

// Language
import { getLanguage } from 'services/language'

// Elements
import BlockDefault from 'components/elements/BlockDefault'

// Icons
import Time from 'img/icon/time.inline.svg'
import Next from 'img/icon/arrow-right-circled-contrast.inline.svg'

const Filter = styled.button`
  border: 4px solid ${props => props.isActive ? props.theme.color.text.secondary : props.theme.color.text.main};
  color: ${props => props.isActive ? props.theme.color.text.secondary : props.theme.color.text.main};
  font-weight: ${props => props.theme.font.weight.xl};
  border-radius: 25px;
  text-align: center;
  position: relative;

  @media (min-width: 992px) {
    padding: 10px;
    min-width: 160px;
  }

  @media (max-width: 991px) {
    padding: 5px 15px;
    min-width: 0;
  }
`

const Close = styled.div`
  border: 2px solid ${props => props.theme.color.text.main};
  background-color: ${props => props.theme.color.light};
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -10px;
  width: 32px;
  height: 32px;

  &:before, &:after {
    background-color: ${props => props.theme.color.contrast};
    position: absolute;
    left: 13px;
    top: 4px;
    content: ' ';
    height: 18px;
    width: 3px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const LoadMore = styled.button`
  font-size: ${props => props.theme.font.size.xm};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.secondary};
  text-decoration: underline;
`

const Blog = ({ fields, ids, hideFilters, hideMore }) => {
  const language = getLanguage()

  const { postsNL, postsEN } = useStaticQuery(graphql`
    {
      postsNL: allWordpressPost(
        sort: { fields: date, order: DESC }
        filter: { wpml_current_locale: { eq: "nl_NL" } }
      ) {
        edges {
          node {
            ...blogContent
          }
        }
      }

      postsEN: allWordpressPost(
        sort: { fields: date, order: DESC }
        filter: { wpml_current_locale: { eq: "en_US" } }
      ) {
        edges {
          node {
            ...blogContent
          }
        }
      }
    }

    fragment blogContent on wordpress__POST {
      wordpress_id
      title
      path
      wpml_current_locale
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        meta {
          readtime
        }
        filter {
          relation
        }
      }
    }
  `)

  const blogContent = language === 'nl_NL' ? postsNL : postsEN

  if(ids) {
    blogContent.edges = blogContent.edges.filter(({ node }) => ids.indexOf(node.wordpress_id) !== -1)
  }

  const limit = 9
  const [selectedFilters, setSelectedFilters] = useState([])
  const [offset, setOffset] = useState(1)

  const setSelectedFiltersLocal = filter => {
    const newSelectedFilters = [ ...selectedFilters ]

    const filterIndex = newSelectedFilters.indexOf(filter)

    if(filterIndex === -1) {
      newSelectedFilters.push(filter)
    } else {
      newSelectedFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`blog`, JSON.stringify(newSelectedFilters))
    }

    setSelectedFilters(newSelectedFilters)
  }

  useEffect(() => {
    if(typeof localStorage !== 'undefined') {
      const storageData = localStorage.getItem('blog')

      if (storageData) {
        setSelectedFilters(JSON.parse(storageData))
      }
    }
  }, [])

  const filters = []

  blogContent.edges.forEach(({ node: { acf: { filter: { relation } } } }) => {
    relation.forEach(node => {
      if(filters.indexOf(node) === -1) {
        filters.push(node)
      }
    })
  })

  const posts = blogContent.edges.filter(({ node: { acf: { filter: { relation } } } }, index) => {
    if((index + 1) > (offset * limit)) {
      return false
    }

    if(selectedFilters.length === 0) {
      return true
    }

    let response = false

    relation.forEach(node => {
      if(selectedFilters.indexOf(node) !== -1) {
        response = true
      }
    })

    return response
  })

  return (
    <>
      {!hideFilters && (
        <div className="d-lg-flex align-items-center mb-4 mb-lg-5">
          <h2 className="font-weight-xl mb-0 mr-5">
            {fields.filter_title}
          </h2>
          <div className="d-md-flex align-items-center mt-2 mt-lg-0">
            {filters.map((filter, index) => {
              const isActive = selectedFilters.indexOf(filter) !== -1

              return (
                <Filter
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  type="button"
                  onClick={() => {
                    setSelectedFiltersLocal(filter)
                  }}
                  isActive={isActive}
                  className="mr-4 mt-3 mt-md-0"
                >
                  {filter}
                  {isActive && (
                    <Close />
                  )}
                </Filter>
              )
            })}
          </div>
        </div>
      )}

      <div className="row">
        {posts.map(
          ({ node: { wordpress_id: id, path, title, acf } }) => (
            <Post key={id} path={path} title={title} acf={acf} fields={fields} />
          )
        )}
      </div>

      {(!hideMore && blogContent.edges.length > posts.length && selectedFilters.length === 0) && (
        <div className="text-center mt-5">
          <LoadMore
            type="button"
            onClick={() => {
              let newOffset = offset

              newOffset += 1

              setOffset(newOffset)
            }}
          >
            {fields.load_more_title}
          </LoadMore>
        </div>
      )}
    </>
  )
}

const PostBlock = styled(BlockDefault)`
  padding: 0 !important;
  overflow: hidden;
`

const PostImage = styled(ImageBackground)`
  padding-top: 60%;
`

const PostTitle = styled.h2`
  font-size: ${props => props.theme.font.size.xm} !important;
  font-weight: ${props => props.theme.font.weight.l};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 75px;
  color: ${props => props.theme.color.text.main};
`

const ReadTime = styled.div`
  font-weight: ${props => props.theme.font.weight.s};
  background-color: ${props => props.theme.color.light};
  border-bottom: 1px solid ${props => props.theme.color.background};
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 10px;
  color: ${props => props.theme.color.text.main};

  & > svg {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    position: relative;
    bottom: 2px;
  }

  & > span {
    display: inline-block;
    margin-left: 5px;
    color: ${props => props.theme.color.text.main};
  }
`

const ReadMore = styled(Link)`
  font-weight: ${props => props.theme.font.weight.s};
  color: ${props => props.theme.color.text.contrast};

  & > svg {
    margin-left: 15px;
    
    @media (min-width: 992px) {
      height: 45px;
      width: 45px;
    }

    @media (max-width: 991px) {      
      height: 35px;
      width: 35px;
    }
  }

  &:hover {
    color: ${props => props.theme.color.text.contrast};
    text-decoration: underline;
  }

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xm};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.m};
  }
`

const Post = ({ title, path, acf, fields }) => (
  <div className="col-sm-6 col-md-4 mt-4">
    <PostBlock>
      <Link to={path}>
      <PostImage src={acf.banner.image}>
      
        <ReadTime className="px-3 py-1">
          <Time />
          {acf.meta.readtime}
          <span>
            {fields.readtime_title}
          </span>
        </ReadTime>
        
      </PostImage>
      <div className="p-4">
        <PostTitle>
          
            {title}
          
        </PostTitle>

        <div className="mt-lg-4 mt-0 d-flex justify-content-end">
          <ReadMore to={path} className="d-flex align-items-center">
            {fields.read_more_title}
            <Next />
          </ReadMore>
        </div>
      </div>
      </Link>
    </PostBlock>
  </div>
)

export default Blog
