/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Blog from 'components/Blog'

// Elements
import HeroAlt from 'components/elements/HeroAlt'
import HeroDescription from 'components/elements/HeroDescription'
import Infographic from '../components/Infopgrahic/Infographic'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        overview {
          readtime_title
          filter_title
          read_more_title
          load_more_title
        }

        show_infographic
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout>
    <SEO
      yoast={yoast}
      path={path}
      image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
    />

    <section>
      <HeroAlt small image={acf.banner.image}>
        <HeroDescription className="d-flex align-items-center justify-content-start h-100">
          {parse(acf.banner.title)}
        </HeroDescription>
      </HeroAlt>
    </section>

    <section>
      <div className="container py-lg-5 my-5">
        <Blog fields={acf.overview} />
      </div>
    </section>

    {acf.show_infographic && (
      <Infographic />
    )}

  </Layout>
)

export default PageTemplate
